<template>
  <div class="text-center">
    <v-overlay :value="loading" z-index="1" :opacity="opacity" :absolute="absolute">
      <v-row align="center" justify="center">
        <v-progress-circular indeterminate color="orange" size="60">
        </v-progress-circular>
      </v-row>
      <v-row align="center" justify="center">
        {{ label }}
      </v-row>
      <v-row align="center" justify="center">
        <slot></slot>
      </v-row>
    </v-overlay>
  </div>
</template>

<script>
export default {
  name: "HosProgressCircular",
  props: { loading: Boolean, absolute: Boolean, opacity: Number, label: String },
};
</script>

<style scoped></style>
