<template>
  <v-container>
    <v-row justify="center" align-content="center">
      <v-col>
        <hos-progress-circular overlay :loading="loading" label="Идет оформление заказа"></hos-progress-circular>
      </v-col>
      <v-col v-if="!loading" cols="12">
        <v-row>
          <v-alert v-if="!hasErrors" text type="success" prominent width="100%" color="success"
            class="py-5 px-5 px-sm-10 px-md-16">
            <v-row no-gutters align="center">
              <span>Ваш заказ успешно оформлен</span>
            </v-row>
          </v-alert>
          <v-alert v-else text type="error" prominent width="100%" color="error" class="py-10 px-5 px-sm-10 px-md-16">
            <v-row no-gutters align="center">
              <span>Выписка не была осуществлена. Пожалуйста, свяжитесь со службой поддержки по номеру +7(495) 215 57
                95</span>
            </v-row>
            <v-row no-gutters align="center">
              <span>Номер заказа: {{ order }}</span>
            </v-row>
          </v-alert>
        </v-row>
      </v-col>
      <v-col>
      </v-col>
    </v-row>
    <div class="pdf-viewer mb-10">
      <div id="mypdf" class="pdf-viewer"></div>
    </div>
  </v-container>
</template>

<script>
import HosProgressCircular from "@/components/UI/HosProgressCircular";
import PDFObject from 'pdfobject';

export default {
  name: "ShowReceipt",
  components: { HosProgressCircular },
  props: {
    order: {
      type: String,
      required: true
    },
    error: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      orderNumber: "",
      loading: true,
      hasErrors: false,
      isDownloading: false,
      pdf: undefined
    };
  },
  methods: {
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },

    async checkOrderStatus() {
      this.loading = true;
      let status = null;
      console.log(`Номер заказа ${this.order}`);
      // status
      //    BOOKED
      //    BOUGHT
      //    ERROR
      try {
        let count = 0;
        do {
          const resp = await this.$axios.get(`/Data/DbStatus?data=${this.order}`);
          status = resp.data
          console.log(resp)
          if (status == "BOOKED") {
            count++;
            await this.sleep(3000)
          }
        } while (status == "BOOKED" && count < 20)
      } catch (e) {
        this.hasErrors = true;
      } finally {
        this.loading = false;
      }

      if (status == "BOUGHT")
        await this.getReceiptInformation();
      else
        this.hasErrors = true;
    },

    async getReceiptInformation() {
      console.log("getReceiptInformation");
      try {
        let resp = await this.$axios.get(
          `/Data/Pdf/GetBase64Service?data=${this.order}`);
        this.pdf = resp.data
        const options = {
          title: "Маршрут-квитанция",
        };
        if (this.pdf != undefined)
          PDFObject.embed(
            'data:application/pdf;base64,' + this.pdf,
            '#mypdf',
            options
          );
      } catch (e) {
        console.log(e.responseText);
        throw e;
      } finally {
        this.isDownloading = false;
      }
      this.loading = false;
    },
  },

  mounted() {
    document.title = "Проверка оплаты";
    this.checkOrderStatus();
  },
};
</script>

<style scoped>
.pdf-viewer {
  height: 680px;
}
</style>
